import React from 'react';
import List from './list'
import Social from './social'
import Footer from './footer'
import { ReactComponent as PsyLogo } from '../svg/psyshit.svg'

// <img src='https://firebasestorage.googleapis.com/v0/b/psyshit-999.appspot.com/o/2022%2Fpsyshit-logo-white.png?alt=media&token=552f0dc9-8a7e-4410-9fc6-85665720b0af' width='150' title='ПСИХЕЯ' alt='ПСИХЕЯ / PSYCHEA'/>

// <img src='https://firebasestorage.googleapis.com/v0/b/psyshit-999.appspot.com/o/2022%2Fpsyshit-logo-color.png?alt=media&token=233233d9-8052-4744-8d33-f7cd45787b1b' width='150' title='ПСИХЕЯ' alt='ПСИХЕЯ / PSYCHEA'/>


// <img src='https://firebasestorage.googleapis.com/v0/b/psyshit-999.appspot.com/o/2022%2Fpsyshit-logo-white.png?alt=media&token=552f0dc9-8a7e-4410-9fc6-85665720b0af' height='200' title='ПСИХЕЯ' alt='ПСИХЕЯ / PSYCHEA'/>

function App() {
  return (
    <div className='main-container'>
      <header className='header'>
        <div>
          <PsyLogo title='ПСИХЕЯ' />
        </div>
        <div className='header__info'>
          <a
             href='https://bnd.lc/psyshit'
             target='_blank'
             title='МУЗЫКА'
             rel='noopener noreferrer'>
             МУЗЫКА
          </a>
          <a
             href='https://vk.com/market-17365092'
             target='_blank'
             title='МЕРЧ'
             rel='noopener noreferrer'>
             МЕРЧ
          </a>
        </div>
        <div className='header__tour'>
          КОНЦЕРТЫ
        </div>
      </header>

      <List />
      <Social />
      <Footer />

      <div className='video-bg'>
        <div className='video-bg__video-overlay'></div>
        </div>

      {/*<div className='video-bg'>
        <div className='video-bg__video-overlay'></div>
        <video className='video-bg__video' playsInline autoPlay muted loop>
          <source src='https://firebasestorage.googleapis.com/v0/b/psyshit-999.appspot.com/o/2022%2Fpsyshitbgvideo.mp4?alt=media&token=88cce7b9-5c84-4ca2-ad89-a8fd5b9872be' type='video/mp4' />
        </video>
      </div>*/}
    </div>
  )
}

export default App;





// function App() {
//   return (
//     <div className='app'>
//       <div className='part'>
//         <div className='header'>
//           <a
//             href='https://bnd.lc/psyshit'
//             target='_blank'
//             rel='noopener noreferrer'>
//             <img src='https://firebasestorage.googleapis.com/v0/b/psyshit-999.appspot.com/o/files%2Fpsychea-logo.png?alt=media&token=89312dae-edda-4288-bd04-f846229b585e'
//               width='200' alt='ПСИХЕЯ'/>
//           </a>
//         </div>
//         <div className='links'>
//           <div className='link'>
//             <a
//                href='https://bnd.lc/psyshit'
//                target='_blank'
//                rel='noopener noreferrer'>
//               <img src='https://firebasestorage.googleapis.com/v0/b/psyshit-999.appspot.com/o/files%2Fmusic.png?alt=media&token=b0069767-f65a-42c8-9d0c-4af27841b225'
//                 width='150' alt='МУЗЫКА'/>
//             </a>
//           </div>
//           <div className='link'>
//             <a
//                href='https://vk.com/market-17365092'
//                target='_blank'
//                rel='noopener noreferrer'>
//               <img src='https://firebasestorage.googleapis.com/v0/b/psyshit-999.appspot.com/o/files%2Fmerch.png?alt=media&token=0964566c-2682-4dbd-8775-b3fc8178e136'
//                 width='150' alt='МЕРЧ'/>
//             </a>
//           </div>
//           <div className='link'>
//             <a
//                href='https://psycheatour.ticketscloud.org/'
//                target='_blank'
//                rel='noopener noreferrer'>
//               <img src='https://firebasestorage.googleapis.com/v0/b/psyshit-999.appspot.com/o/files%2Ftour.png?alt=media&token=2b16ab93-827f-4f3b-b777-e62488e8cf51'
//                 width='150' alt='ТУР'/>
//             </a>
//           </div>
//         </div>
//       </div>
//       <div className='skull'>
//         <img src='https://firebasestorage.googleapis.com/v0/b/psyshit-999.appspot.com/o/files%2Fpsy-skull.png?alt=media&token=067f4fd6-0553-49c2-ad39-636f65ab0040'
//           width='100' alt='SKULL'/>
//       </div>
//       <div className='footer'>
//         <img src='https://firebasestorage.googleapis.com/v0/b/psyshit-999.appspot.com/o/files%2Fpsyshitcom2021.png?alt=media&token=473927b7-27fd-4406-8cb7-367a8b8d6292'
//           width='100' alt='psyshit.com 2021' />
//       </div>
//     </div>
//   )
// }






// <div className='header'>
//   <img src='https://firebasestorage.googleapis.com/v0/b/psyshit-999.appspot.com/o/files%2Fpsychea-logo.png?alt=media&token=89312dae-edda-4288-bd04-f846229b585e'
//     width='200' alt='ПСИХЕЯ'/>
// </div>
// <div className='links'>
//   <div>
//     <a
//        href='https://bnd.lc/psyshit'
//        target='_blank'
//        rel='noopener noreferrer'>
//       <img src='https://firebasestorage.googleapis.com/v0/b/psyshit-999.appspot.com/o/files%2Fmusic.png?alt=media&token=b0069767-f65a-42c8-9d0c-4af27841b225'
//         width='150' alt='МУЗЫКА'/>
//     </a>
//   </div>
//   <div>
//     <a
//        href='https://vk.com/market-17365092'
//        target='_blank'
//        rel='noopener noreferrer'>
//       <img src='https://firebasestorage.googleapis.com/v0/b/psyshit-999.appspot.com/o/files%2Fmerch.png?alt=media&token=0964566c-2682-4dbd-8775-b3fc8178e136'
//         width='150' alt='МЕРЧ'/>
//     </a>
//   </div>
//   <div>
//     <a
//        href='https://psycheatour.ticketscloud.org/'
//        target='_blank'
//        rel='noopener noreferrer'>
//       <img src='https://firebasestorage.googleapis.com/v0/b/psyshit-999.appspot.com/o/files%2Ftour.png?alt=media&token=2b16ab93-827f-4f3b-b777-e62488e8cf51'
//         width='150' alt='ТУР'/>
//     </a>
//   </div>
// </div>
